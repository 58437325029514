import { FC, useState, useMemo, useCallback } from 'react';
import {
  CardItem,
  StatusBox,
  Input,
  Typography,
  Button,
  YellowButton,
  GreenButton,
  Modal,
} from 'iglooform';
import type { FormItemConfig } from 'iglooform/es/types';
import { SearchOutlined } from 'iglooicon';
import { Spin } from 'antd';
import BackTo from '@/components/back-to';
import Card from '@/components/card';
import Table from '@/components/table';
import { IRouteComponentProps, history } from 'umi';
import { useRequest } from 'ahooks';
import {
  getProjectDetail,
  getProjectResource,
  startTranslation,
  startReview,
  active,
} from '../../service';
import {
  projectState,
  userInfoState,
  translatorState,
  reviewerState,
} from '@/store';
import { useRecoilState, useRecoilValue } from 'recoil';
import moment from 'moment';
import useProjectModal from '@/components/project-modal';
import styles from './index.less';

const ProjectStatus: { [key: string]: any } = {
  ACTIVE: 'warning',
  LOCKED: 'waiting',
  READY: 'success',
};

const ProjectDetail: FC<IRouteComponentProps<{ id: string }>> = ({ match }) => {
  const [projectDetail, setProject] = useRecoilState(projectState);
  const userInfo = useRecoilValue(userInfoState);
  const translator = useRecoilValue(translatorState);
  const reviewer = useRecoilValue(reviewerState);
  const [resources, setResources] = useState<English[]>([]);
  const [updatedAt, setUpdatedAt] = useState<number>();
  const id = match.params.id;
  const { run: refreshDetail, loading } = useRequest<Project>(
    getProjectDetail,
    {
      manual: false,
      defaultParams: [id],
      onSuccess: setProject,
    },
  );

  const {
    frontEngineers = [],
    productManagers = [],
    status,
    languages,
    contextReference,
    name,
    statusUpdatedAt,
    createdAt,
    translating,
    reviewing,
  } = projectDetail || ({} as Project);

  const {
    data,
    run: refreshResource,
    loading: resLoading,
  } = useRequest<{
    data: English[];
    updatedAt: number;
  }>(getProjectResource, {
    manual: false,
    defaultParams: [id],
    onSuccess: (data) => {
      if (!data) return;

      setResources(data.data);
      setUpdatedAt(data.updatedAt);
    },
  });

  const showModal = useProjectModal({
    projectDetail,
    onSuccess: () => refreshDetail(id),
  });

  const isOwner = useMemo(
    () => [...frontEngineers, ...productManagers].includes(userInfo.email),
    [frontEngineers, productManagers, userInfo],
  );

  const submitReview = async () => {
    await startReview(id);
    refreshDetail(id);
  };

  const handleRelease = async () => {
    await active(id);
    refreshDetail(id);
  };

  const projectButtons = useMemo(() => {
    const buttons = [];

    if (
      status === 'ACTIVE' &&
      userInfo.permissions.includes('EditProjectInfo')
    ) {
      buttons.push(
        <Button className={styles.button} onClick={showModal}>
          Edit
        </Button>,
      );
    }

    if (
      isOwner &&
      status === 'ACTIVE' &&
      translating &&
      userInfo.permissions.includes('SubmitReview')
    ) {
      buttons.push(
        <YellowButton className={styles.button} onClick={submitReview}>
          Submit to Review
        </YellowButton>,
      );
    }

    if (
      isOwner &&
      status === 'READY' &&
      userInfo.permissions.includes('Release')
    ) {
      buttons.push(
        <GreenButton className={styles.button} onClick={handleRelease}>
          Release
        </GreenButton>,
      );
    }

    return buttons;
  }, [status, isOwner, userInfo.permissions, translating, showModal]);

  const submitTranslation = useCallback(() => {
    const formElements: FormItemConfig[] = [
      {
        type: 'Select',
        label: 'Translation Language',
        name: 'languages',
        options: languages.map((lang) => ({ label: lang, value: lang })),
        multiple: true,
        halfRow: true,
      },
    ];

    languages.forEach((lang) => {
      formElements.push(
        {
          type: 'Select',
          label: `${lang} Translator`,
          name: `${lang}_translator`,
          showSearch: true,
          options: translator[lang].map((email) => ({
            label: email,
            value: email,
          })),
          asserts: [
            {
              operation: 'co',
              field: 'languages',
              value: lang,
            },
          ],
        },
        {
          type: 'Select',
          label: `${lang} Reviewer`,
          name: `${lang}_reviewer`,
          showSearch: true,
          options: reviewer[lang].map((email) => ({
            label: email,
            value: email,
          })),
          asserts: [
            {
              operation: 'co',
              field: 'languages',
              value: lang,
            },
          ],
        },
      );
    });

    const handleOk = async (values: any) => {
      const { languages } = values;

      await startTranslation(
        id,
        languages.map((language: any) => ({
          language,
          translatorEmail: values[`${language}_translator`],
          reviewerEmail: values[`${language}_reviewer`],
        })),
      );

      refreshDetail(id);
    };

    Modal.confirm({
      title: 'Choose Translators and Reviewers',
      maskClosable: false,
      formConfig: {
        type: 'Page',
        elements: formElements,
      },
      mode: 'form',
      className: styles.modal,
      onOk: handleOk,
    });
  }, [translator, reviewer, id]);

  const resourceButtons = useMemo(() => {
    const buttons = [];

    if (isOwner && userInfo.permissions.includes('ManageEnglish')) {
      buttons.push(
        <Button
          className={styles.button}
          onClick={() => history.push(`${match.url}/edit`)}
        >
          Update
        </Button>,
      );
    }

    if (
      isOwner &&
      status === 'ACTIVE' &&
      userInfo.permissions.includes('SubmitTranslation')
    ) {
      buttons.push(
        <YellowButton
          className={styles.button}
          onClick={submitTranslation}
          disabled={!languages?.length}
        >
          Submit to Translate
        </YellowButton>,
      );
    }

    return buttons;
  }, [status, isOwner, userInfo.permissions, submitTranslation, languages]);

  const handleFilter = (keyword: string) => {
    const { data: origin = [] } = data || {};

    setResources(
      origin.filter(({ name }) =>
        name.toLowerCase().includes(keyword.toLowerCase()),
      ),
    );
  };

  return (
    <Spin spinning={loading || resLoading}>
      <div className={styles.container}>
        <BackTo targetName="Project List" targetPath="/projects" />
        <Card
          title={`Project: ${name}`}
          pageStatusIcon={
            <StatusBox type={ProjectStatus[status]}>{status}</StatusBox>
          }
          extraButtons={projectButtons}
        >
          <CardItem title="Product Manager" span={6}>
            {productManagers?.join('\n') || '-'}
          </CardItem>
          <CardItem title="Front-End Tech" span={6}>
            {frontEngineers?.join('\n') || '-'}
          </CardItem>
          <CardItem title="Multi-lingual Language" span={6}>
            {languages?.join(', ') || '-'}
          </CardItem>
          <CardItem title="Project Create Time" span={6}>
            {createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm:ss') : '-'}
          </CardItem>
          <CardItem title="Project State Updated" span={6}>
            {statusUpdatedAt
              ? moment(statusUpdatedAt).format('DD/MM/YYYY HH:mm:ss')
              : '-'}
          </CardItem>
          <CardItem title="Multi-lingual Context Reference" span={18}>
            {contextReference || '-'}
          </CardItem>
        </Card>
        <Card
          title="English Resource"
          style={{ marginTop: 24 }}
          className={styles.resources}
          extraButtons={resourceButtons}
        >
          <div className={styles.searchBar}>
            <Input
              prefix={<SearchOutlined style={{ fontSize: 24 }} />}
              className={styles.input}
              onPressEnter={(e) => handleFilter((e.target as any).value)}
            />
            <Typography level="body2" className={styles.updatedAt}>
              Last updated at:{' '}
              {updatedAt
                ? moment(updatedAt).format('DD/MM/YYYY HH:mm:Ss')
                : '-'}
            </Typography>
          </div>
          <Table
            key={'1'}
            dataSource={resources}
            columns={[
              {
                title: 'No',
                dataIndex: 'id',
                render: (id: number, row: any, index: number) => index + 1,
              },
              {
                title: 'Item(EN)',
                dataIndex: 'name',
                className: styles.tableColumn,
              },
              {
                title: 'Context Reference',
                dataIndex: 'contextReference',
              },
            ]}
            rowKey="id"
          />
        </Card>
      </div>
    </Spin>
  );
};

export default ProjectDetail;
