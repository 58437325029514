// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/data/jenkins_home/workspace/dev-gitlab-build-web-lina/go/src/github.com/axinan-com/goserver/lina/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.tsx').default,
    "routes": [
      {
        "path": "/403",
        "exact": true,
        "component": require('@/pages/403.tsx').default
      },
      {
        "path": "/alphabet",
        "exact": true,
        "component": require('@/pages/alphabet/index.tsx').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.tsx').default
      },
      {
        "path": "/projects",
        "exact": true,
        "component": require('@/pages/projects/index.tsx').default
      },
      {
        "path": "/projects/:id",
        "routes": [
          {
            "path": "/projects/:id/detail/edit",
            "exact": true,
            "component": require('@/pages/projects/[id]/detail/edit/index.tsx').default
          },
          {
            "path": "/projects/:id/detail",
            "exact": true,
            "component": require('@/pages/projects/[id]/detail/index.tsx').default
          },
          {
            "path": "/projects/:id/multi-lingual/ai/:language",
            "exact": true,
            "component": require('@/pages/projects/[id]/multi-lingual/ai/[language].tsx').default
          },
          {
            "path": "/projects/:id/multi-lingual/edit/:language",
            "exact": true,
            "component": require('@/pages/projects/[id]/multi-lingual/edit/[language].tsx').default
          },
          {
            "path": "/projects/:id/multi-lingual",
            "exact": true,
            "component": require('@/pages/projects/[id]/multi-lingual/index.tsx').default
          }
        ],
        "component": require('@/pages/projects/[id]/_layout.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
