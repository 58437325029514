import { FC, useEffect } from 'react';
import { IRouteComponentProps, history, useLocation } from 'umi';
import { Layout, Menu } from 'antd';
import { OverviewOutlined, ProjectOutlined } from 'iglooicon';
import { translatorState, reviewerState, languagesState } from '@/store';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { getIglooUser } from '@/services';
import styles from './layout.less';

const ProjectLayout: FC<IRouteComponentProps<{ id: string }>> = ({
  children,
  match,
}) => {
  const location = useLocation();
  const pathName = location?.pathname;
  const { params } = match;

  const languages = useRecoilValue(languagesState);
  const setTranslator = useSetRecoilState(translatorState);
  const setReviewer = useSetRecoilState(reviewerState);

  useEffect(() => {
    if (!languages?.length) return;

    const getTranslators = async () => {
      const results = await Promise.all(
        languages.map(({ name }) => getIglooUser(`Translator_${name}`)),
      );

      const translator: any = {};
      languages.forEach(({ name }, index) => {
        translator[name] = results[index]?.data?.map(({ email }: any) => email);
      });

      setTranslator(translator);
    };

    const getReviewers = async () => {
      const results = await Promise.all(
        languages.map(({ name }) => getIglooUser(`Reviewer_${name}`)),
      );

      const reviewer: any = {};
      languages.forEach(({ name }, index) => {
        reviewer[name] = results[index]?.data?.map(({ email }: any) => email);
      });

      setReviewer(reviewer);
    };

    getTranslators();
    getReviewers();
  }, [languages]);

  const clickMenu = ({ key }: any) => {
    history.push(
      key === 'detail'
        ? `/projects/${params.id}/detail`
        : `/projects/${params.id}/multi-lingual`,
    );
  };

  return (
    <Layout className={styles.layout}>
      <Layout.Sider className={styles.sider}>
        <Menu
          mode="inline"
          className={styles.menu}
          selectedKeys={
            pathName.includes('detail') ? ['detail'] : ['multi-lingual']
          }
          onClick={clickMenu}
        >
          <Menu.Item key="detail" className={styles.menuItem}>
            <ProjectOutlined className={styles.icon} />
            <span className={styles.text}>Project Info</span>
          </Menu.Item>

          <Menu.Item key="multi-lingual" className={styles.menuItem}>
            <OverviewOutlined className={styles.icon} />
            <span className={styles.text}>Multi-lingual Resource</span>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout.Content className={styles.content}>{children}</Layout.Content>
    </Layout>
  );
};

export default ProjectLayout;
